import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
@Component({
  selector: "app-group-master",
  templateUrl: "./group-master.component.html",
  styleUrls: ["./group-master.component.scss"],
})
export class GroupMasterComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  loading: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [
    "GroupId",
    "Group_Name",
    "Alias",
    "Level",
    "ParentGroup",
    "TAG",
    "Separate",
    "BlanceReporting",
    "Action",
  ];
  isEditEnabled = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  groupList: any[] = [];
  groupDropdown: any[] = [];
  groupModel: any = {};

  pageTitle: any = "Group Master";
  PageAcess: any;

  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    private lmsService: LmsService,
    private dialog: DialogService
  ) {}
  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Group Master");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.Get_Acc_Grouplist();
    this.Get_Acc_GroupDropdown();
  }
  Get_Acc_Grouplist() {
    this.showSpinner = true;
    this.lmsService.Get_Acc_Grouplist({}).subscribe((res: any) => {
      console.log(res);
      if (res.length > 0) {
        this.groupList = JSON.parse(JSON.stringify(res));
        this.dataSource = new MatTableDataSource(this.groupList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
        console.log("All PAGES", AllPage);
        this.PageAcess = AllPage.filter((x) => x.Page_Name == "Group Master");

        this.showSpinner = false;
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No data found",
          ...this.configSuccess,
        });
        this.showSpinner = false;
      }
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  addnewGroup() {
    this.groupModel = {
      GroupId: 0,
      Group_ParentId: 0,
      Group_Name: "",
      Group_Alias: "",
      Group_Level: "",
      Group_Tag: "",
      Group_IsAnnuxure: "",
      Group_AnnuxureNo: "",
      Group_IsBlanceReporting: "",
      Group_Descriptions: "",
      LoginUserId: this.currentUser.userId,
    };
    this.Get_Acc_GroupDropdown();
    $("#AddNewGroupModal").modal("show");
    $("#AddNewGroupModal").css("z-index", "1050");
  }
  onEdit(data: any) {
    this.isEditEnabled = false;
    this.groupModel = {
      GroupId: data.GroupId,
      Group_ParentId: data.Parent_GroupId,
      Group_Name: data.Group_Name,
      Group_Alias: data.Alias,
      Group_Level: data.Level,
      Group_Tag: data.TAG,
      Group_IsAnnuxure: data.Separate == "Yes" ? "1" : "0",
      Group_AnnuxureNo: data.Separate_Annexure,
      Group_IsBlanceReporting: data.BlanceReporting == "Yes" ? "1" : "0",
      Group_Descriptions: data.Group_Descriptions,
      LoginUserId: this.currentUser.userId,
    };
    $("#AddNewGroupModal").modal("show");
    $("#AddNewGroupModal").css("z-index", "1050");
  }

  enableEdit() {
    this.isEditEnabled = true;
  }
  onDelete(data: any) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to remove ${data.Group_Name} ?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.lmsService
            .Delete_AccGroup({
              GroupId: data.GroupId,
              LoginUserId: this.currentUser.userId,
            })
            .subscribe((res: any) => {
              this.showSpinner = false;
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.Get_Acc_Grouplist();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }
  Get_Acc_GroupDropdown() {
    this.lmsService.Get_Acc_GroupDropdown({}).subscribe((res: any) => {
      this.groupDropdown = res;
    });
  }
  OnCloseGroupModal() {
    $("#AddNewGroupModal").modal("hide");
  }
  onSaveGroup() {
    this.loading = true;
    this.lmsService
      .Save_AccGroupMasters({
        JSON: JSON.stringify(
          this.dataSharingService.cleanObject(this.groupModel)
        ),
      })
      .subscribe((res: any) => {
        this.loading = false;
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
          this.Get_Acc_Grouplist();
          this.OnCloseGroupModal();
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });
        }
      });
  }
  setGroupLevel() {
    let _find = this.groupDropdown.find((obj: any) => {
      return obj.GroupId == this.groupModel.Group_ParentId;
    });
    console.log(_find);
    if (_find) {
      this.groupModel.Group_Level = _find.Group_Level + 1;
    } else {
      this.groupModel.Group_Level = "";
    }
  }

  exportTable(exporter: any) {
    exporter.exportTable("xls", {
      fileName: this.pageTitle,
      sheet: this.pageTitle,
    });
  }
}
