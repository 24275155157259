<app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
<div class="row m-0 col-md-12 p-0 formborder d-flex align-items-center">
  <div class="col-md-4">
    <form action="" class="search-text">
      <div class="bg-light rounded rounded-pill shadow-sm">
        <div class="input-group">
          <input
            type="text"
            id="FilterInputSearch"
            (keyup)="applyFilter($event.target.value)"
            name="FilterInputSearch"
            placeholder="What're you searching for?"
            class="form-control border-0 bg-light"
          />
          <div class="input-group-append">
            <button
              id="button-addon1"
              type="submit"
              class="btn btn-link text-primary"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="text-right col-md-6">
    <button
      type="button"
      (click)="addnewGroup()"
      class="mt-3 btn font-size-12 button-btn"
      *ngIf="PageAcess && PageAcess[0].Add == 1"
    >
      Add New
    </button>
  </div>
</div>

<div class="mt-3" [hidden]="!dataSource">
  <div class="">
    <div class="table-responsive" style="overflow: auto">
      <mat-table
        class="table-bordered"
        matSort
        [dataSource]="dataSource"
        matTableExporter
        #exporter="matTableExporter"
        [hiddenColumns]="[
          displayedColumns.indexOf('GroupId'),
          displayedColumns.indexOf('Action')
        ]"
        style="overflow: auto; max-width: 100%; height: 60vh"
      >
        <ng-container matColumnDef="GroupId">
          <mat-header-cell mat-sort-header *matHeaderCellDef class="width50">
            #
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index" class="width50">
            {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Group_Name">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Group Name</mat-header-cell
          >
          <mat-cell *matCellDef="let row">{{ row.Group_Name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Alias">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Alias</mat-header-cell
          >
          <mat-cell *matCellDef="let row">{{ row.Alias }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Level">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Level</mat-header-cell
          >
          <mat-cell *matCellDef="let row">{{ row.Level }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="ParentGroup">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Parent Group
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.ParentGroup }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="TAG">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >TAG</mat-header-cell
          >
          <mat-cell *matCellDef="let row">{{ row.TAG }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Separate">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Annexure</mat-header-cell
          >
          <mat-cell *matCellDef="let row">{{ row.Separate_Annexure }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="BlanceReporting">
          <mat-header-cell mat-sort-header *matHeaderCellDef>
            Blance Reporting
          </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.BlanceReporting }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Action">
          <mat-header-cell
            mat-sort-header
            style="max-width: 60px"
            *matHeaderCellDef
          >
            Action
          </mat-header-cell>
          <mat-cell *matCellDef="let row" style="max-width: 60px">
            <div style="display: flex; align-items: center; gap: 10px">
              <i
                class="fa fa-eye"
                (click)="onEdit(row)"
                style="cursor: pointer; font-size: 14px"
                aria-label="true"
                *ngIf="row.Level != 0"
              ></i>
              <i
                class="fa fa-trash style-delete"
                (click)="onDelete(row)"
                style="cursor: pointer; font-size: 14px"
                aria-label="true"
                *ngIf="row.Level != 0 && PageAcess && PageAcess[0].Delete == 1"
              ></i>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
<div class="row m-0 ml-3 mr-3 pt-2 pb-1" [hidden]="!dataSource">
  <div class="col-md-4">
    <button
      class="mt-3 btn font-size-12 button-btn"
      (click)="exportTable(exporter)"
    >
      <i
        class="fa fa-file-export mr-1"
        style="font-size: medium; cursor: pointer"
      ></i>
      Export
    </button>
  </div>
  <div class="col-md-8 text-right">
    <mat-paginator
      #paginatorRef
      [pageSizeOptions]="[20, 50]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>

<div
  class="modal fade in"
  id="AddNewGroupModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <form #f="ngForm" (ngSubmit)="onSaveGroup()" novalidate>
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
        <div cdkDragHandle class="modal-header" style="padding: 10px">
          <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
            Add New Group
          </h6>
          <button
            type="button"
            (click)="OnCloseGroupModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i class="far text-black fa-times-circle"></i>
            </span>
          </button>
        </div>
        <div class="modal-body formborder m-3">
          <div class="row mt-2">
            <div class="col-md-4">
              <span class="required-lable">Group Name</span>
              <input
                [disabled]="!isEditEnabled"
                required
                type="text"
                #refGroup_Name="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refGroup_Name.invalid,
                  'alert-warning':
                    refGroup_Name.invalid &&
                    (refGroup_Name.dirty ||
                      refGroup_Name.touched ||
                      refGroup_Name.untouched)
                }"
                [(ngModel)]="groupModel.Group_Name"
                name="Group_Name"
                id="Group_Name"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-4">
              <span class="">Group Alias</span>
              <input
                [disabled]="!isEditEnabled"
                type="text"
                #refGroup_Alias="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refGroup_Alias.invalid,
                  'alert-warning':
                    refGroup_Alias.invalid &&
                    (refGroup_Alias.dirty ||
                      refGroup_Alias.touched ||
                      refGroup_Alias.untouched)
                }"
                [(ngModel)]="groupModel.Group_Alias"
                name="Group_Alias"
                id="Group_Alias"
                class="form-control input-text-css"
              />
            </div>
            <div class="col-md-4">
              <span class="required-lable">Parent Group</span>
              <select
                name="Group_ParentId"
                id="Group_ParentId"
                #refGroup_ParentId="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refGroup_ParentId.invalid,
                  'alert-warning':
                    refGroup_ParentId.invalid &&
                    (refGroup_ParentId.dirty ||
                      refGroup_ParentId.touched ||
                      refGroup_ParentId.untouched)
                }"
                [(ngModel)]="groupModel.Group_ParentId"
                class="form-control input-text-css"
                (change)="setGroupLevel()"
                [disabled]="!isEditEnabled"
              >
                <option value="0">Select Parent Group</option>
                <option
                  *ngFor="let data of groupDropdown"
                  [value]="data.GroupId"
                >
                  {{ data.Group_Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-4">
              <span class="required-lable">Group Level</span>
              <input
                type="text"
                required
                readonly
                #refGroup_Levele="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refGroup_Levele.invalid,
                  'alert-warning':
                    refGroup_Levele.invalid &&
                    (refGroup_Levele.dirty ||
                      refGroup_Levele.touched ||
                      refGroup_Levele.untouched)
                }"
                [(ngModel)]="groupModel.Group_Level"
                name="Group_Level"
                id="Group_Level"
                class="form-control input-text-css"
                [disabled]="!isEditEnabled"
              />
            </div>
            <div class="col-md-4">
              <span class="required-lable">Group Tag</span>
              <select
                [disabled]="!isEditEnabled"
                name="Group_Tag"
                required
                [disabled]="groupModel.GroupId != 0"
                id="Group_Tag"
                #refGroup_Tag="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refGroup_Tag.invalid,
                  'alert-warning':
                    refGroup_Tag.invalid &&
                    (refGroup_Tag.dirty ||
                      refGroup_Tag.touched ||
                      refGroup_Tag.untouched)
                }"
                [(ngModel)]="groupModel.Group_Tag"
                class="form-control input-text-css"
              >
                <option value="">Select Group Tag</option>
                <option value="Default">Default</option>
                <option value="Sundry">Sundry</option>
                <option value="Loan">Loan</option>
                <option value="D&T">D&T</option>
                <option value="Bank">Bank</option>
                <option value="Cash">Cash</option>
                <option value="Asset">Asset</option>
              </select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-4">
              <span class="required-lable">Balance For Reporting</span>
              <div class="col-md-12 p-0 m-0">
                <span class="form-check-inline">
                  <label
                    class="form-check-label"
                    for="Group_IsBlanceReportingYes"
                  >
                    <input
                      [disabled]="!isEditEnabled"
                      type="radio"
                      class="form-check-input"
                      id="Group_IsBlanceReportingYes"
                      name="Group_IsBlanceReporting"
                      #refGroup_IsBlanceReporting="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refGroup_IsBlanceReporting.invalid,
                        'alert-warning':
                          refGroup_IsBlanceReporting.invalid &&
                          (refGroup_IsBlanceReporting.dirty ||
                            refGroup_IsBlanceReporting.touched ||
                            refGroup_IsBlanceReporting.untouched)
                      }"
                      [(ngModel)]="groupModel.Group_IsBlanceReporting"
                      required
                      value="1"
                    />
                    Yes
                  </label>
                </span>
                <span class="form-check-inline">
                  <label
                    class="form-check-label"
                    for="Group_IsBlanceReportingNo"
                  >
                    <input
                      [disabled]="!isEditEnabled"
                      type="radio"
                      class="form-check-input"
                      id="Group_IsBlanceReportingNo"
                      name="Group_IsBlanceReporting"
                      #refGroup_IsBlanceReporting="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refGroup_IsBlanceReporting.invalid,
                        'alert-warning':
                          refGroup_IsBlanceReporting.invalid &&
                          (refGroup_IsBlanceReporting.dirty ||
                            refGroup_IsBlanceReporting.touched ||
                            refGroup_IsBlanceReporting.untouched)
                      }"
                      [(ngModel)]="groupModel.Group_IsBlanceReporting"
                      required
                      value="0"
                    />
                    No
                  </label>
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <span class="required-lable">Use Saparate Annuxure</span>
              <div class="col-md-12 p-0 m-0">
                <span class="form-check-inline">
                  <label class="form-check-label" for="Group_IsAnnuxureYes">
                    <input
                      [disabled]="!isEditEnabled"
                      type="radio"
                      class="form-check-input"
                      id="Group_IsAnnuxureYes"
                      name="Group_IsAnnuxure"
                      #refGroup_IsAnnuxure="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refGroup_IsAnnuxure.invalid,
                        'alert-warning':
                          refGroup_IsAnnuxure.invalid &&
                          (refGroup_IsAnnuxure.dirty ||
                            refGroup_IsAnnuxure.touched ||
                            refGroup_IsAnnuxure.untouched)
                      }"
                      [(ngModel)]="groupModel.Group_IsAnnuxure"
                      required
                      value="1"
                    />
                    Yes
                  </label>
                </span>
                <span class="form-check-inline">
                  <label class="form-check-label" for="Group_IsAnnuxureNo">
                    <input
                      [disabled]="!isEditEnabled"
                      type="radio"
                      class="form-check-input"
                      id="Group_IsAnnuxureNo"
                      name="Group_IsAnnuxure"
                      #refGroup_IsAnnuxure="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          f.submitted && refGroup_IsAnnuxure.invalid,
                        'alert-warning':
                          refGroup_IsAnnuxure.invalid &&
                          (refGroup_IsAnnuxure.dirty ||
                            refGroup_IsAnnuxure.touched ||
                            refGroup_IsAnnuxure.untouched)
                      }"
                      [(ngModel)]="groupModel.Group_IsAnnuxure"
                      required
                      value="0"
                    />
                    No
                  </label>
                </span>
              </div>
            </div>
            <div class="col-md-4" *ngIf="groupModel.Group_IsAnnuxure == 1">
              <span class="required-lable">Annuxure No</span>
              <input
                [disabled]="!isEditEnabled"
                required
                type="text"
                numbersOnly
                #refGroup_AnnuxureNo="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refGroup_AnnuxureNo.invalid,
                  'alert-warning':
                    refGroup_AnnuxureNo.invalid &&
                    (refGroup_AnnuxureNo.dirty ||
                      refGroup_AnnuxureNo.touched ||
                      refGroup_AnnuxureNo.untouched)
                }"
                [(ngModel)]="groupModel.Group_AnnuxureNo"
                name="Group_AnnuxureNo"
                id="Group_AnnuxureNo"
                class="form-control input-text-css"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <span class="">Group Descriptions</span>
              <textarea
                [disabled]="!isEditEnabled"
                #refGroup_Descriptions="ngModel"
                [ngClass]="{
                  'is-invalid': f.submitted && refGroup_Descriptions.invalid,
                  'alert-warning':
                    refGroup_Descriptions.invalid &&
                    (refGroup_Descriptions.dirty ||
                      refGroup_Descriptions.touched ||
                      refGroup_Descriptions.untouched)
                }"
                [(ngModel)]="groupModel.Group_Descriptions"
                name="Group_Descriptions"
                id="Group_Descriptions"
                class="form-control input-text-css"
                rows="2"
              >
              </textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="OnCloseGroupModal()"
            class="mt-2 btn font-size-12 button-btn"
            data-dismiss="modal"
            
          >
            Cancel
          </button>
          <button
            type="button"
            (click)="onSaveGroup()"
            class="mt-2 btn font-size-12 button-btn"
            [class.spinner]="loading"
            [disabled]="!f.form.valid"
            *ngIf="isEditEnabled"
          >
            Save
          </button>
          <button
            type="button"
            (click)="enableEdit()"
            class="mt-2 btn font-size-12 button-btn"
            [class.spinner]="loading"
            *ngIf="!isEditEnabled && PageAcess && PageAcess[0].Edit == 1"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
