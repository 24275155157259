import { Component, OnInit, ViewChild } from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { MasterService } from "../../Shared/app.Masters.Service";
@Component({
  selector: "app-accounting-master",
  templateUrl: "./accounting-master.component.html",
  styleUrls: ["./accounting-master.component.scss"],
})
export class AccountingMasterComponent implements OnInit {
  showSpinner: boolean = false;
  currentUser: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  loading: boolean = false;
  dataSource: any;
  displayedColumns: string[] = [
    "AccountId",
    "Name",
    "Group",
    "OpenDate",
    "CloseDate",
    "OpeningBalance",
    "LoanChargeable",
    "GSTEnable",
    "Branches",
    "Action",
  ];

  pageTitle: any = "Account Master";
  PageAcess: any;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  accountList: any[] = [];
  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private router: Router,
    private lmsService: LmsService,
    private dialog: DialogService,
    private masterService: MasterService
  ) {}

  ngOnInit() {
    this.dataSharingService.HeaderTitle.next(this.pageTitle);
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetAccAccountingList();
  }
GetAccAccountingList() {
    this.showSpinner = true;
    this.lmsService.GetAccAccountingList({}).subscribe((res: any) => {
      if (res.length > 0) {
        this.accountList = JSON.parse(JSON.stringify(res));
        this.dataSource = new MatTableDataSource(this.accountList);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.showSpinner = false;
        let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
        console.log("All PAGES", AllPage);
        this.PageAcess = AllPage.filter(
          (x) => x.Page_Name == "Accounting Master"
        );
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "No data found",
          ...this.configSuccess,
        });
        this.showSpinner = false;
      }
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToDetail(data: any) {
    if (data && data.AccountId) {
      this.router.navigate(
        [
          `/lms-accounting/accounting-master/${encodeURIComponent(
            data.AccountId
          )}`,
        ],
        { queryParams: { mode: "view" } }
      );
    } else {
      this.router.navigate(
        [`/lms-accounting/accounting-master/${encodeURIComponent("new")}`],
        { queryParams: { mode: "edit" } }
      );
    }
  }

  onDelete(data: any) {
    this.dialog
      .openConfirmDialog(`Are you sure you want to remove ${data.Name} ?`)
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult == false) {
          return;
        } else {
          this.showSpinner = true;
          this.lmsService
            .Delete_Acc_Account_Master({
              AccountIdentity: data.AccountId,
              LoginUserId: this.currentUser.userId,
            })
            .subscribe((res: any) => {
              this.showSpinner = false;
              if (res[0].CODE >= 0) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
                this.GetAccAccountingList();
              } else {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: res[0].MSG,
                  ...this.configSuccess,
                });
              }
            });
        }
      });
  }

  exportTable(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "accounting_master"
    ) as HTMLTableElement;
    const worksheetName = "ACCOUNTING MASTER"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }
}
