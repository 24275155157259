<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchAccountStatement()"
    class="formborder"
  >
    <div class="row m-0 col-md-12 p-0">
      <div class="col-md-4">
        <span class="required-lable">Bank Name</span>

        <input
          type="text"
          [formControl]="AccountControl"
          [matAutocomplete]="auto"
          class="form-control input-text-css"
          [(ngModel)]="chhead"
          name="chhead"
          required
          [class.alert-warning]="chhead == ''"
          placeholder="Bank Name"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onAccountHeadhSelect($event)"
        >
          <mat-option
            *ngFor="let account of filteredAccountHead | async"
            [value]="account"
          >
            {{ account.Account_Name }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-md-2">
        <span>Date From</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="CaseDateFrom"
            id="VoucherDateFrom"
            [max]="maxDate"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateFrom"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <!-- <input
          name="CaseDateFrom"
          [(ngModel)]="VoucherDateFrom"
          id="VoucherDateFrom"
          [maxDate]="maxDate"
          placeholder="Voucher From"
          class="form-control input-text-css"
          bsDatepicker
        /> -->
      </div>
      <div class="col-md-2">
        <span>Date To</span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="VoucherDateTo"
            id="VoucherDateTo"
            class="form-control input-text-css"
            [(ngModel)]="VoucherDateTo"
            (dateChange)="dateChange()"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
        <!-- <input
          name="VoucherDateTo"
          [(ngModel)]="VoucherDateTo"
          id="VoucherDateTo"
          [minDate]="minDate"
          [maxDate]="maxDate"
          placeholder="Voucher To"
          (ngModelChange)="dateChange()"
          class="form-control input-text-css"
          bsDatepicker
        /> -->
      </div>
      <div class="col-md-2">
        <span> Entry</span>
        <select
          name="TransationDetail"
          id="TransationDetail"
          class="form-control input-text-css"
          [(ngModel)]="TransationDetail"
        >
          <option [value]="1">All Entry</option>
          <option [value]="0">Pending Entry Only</option>
        </select>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          (click)="onSearchAccountStatement()"
          class="mt-3 btn font-size-12 button-btn"
        >
          Search
        </button>
      </div>

      <div class="col-md-12">
        <div class="col-md-4 float-right" *ngIf="dataSource">
          <form action="" class="search-text">
            <div class="bg-light rounded rounded-pill shadow-sm">
              <div class="input-group">
                <input
                  type="text"
                  id="FilterInputSearch"
                  (keyup)="applyFilter($event.target.value)"
                  name="FilterInputSearch"
                  placeholder="What're you searching for?"
                  class="form-control border-0 bg-light mt-2"
                />
                <div class="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    class="btn btn-link text-primary"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </form>

  <div class="mt-3">
    <div class="">
      <div class="table-responsive" [hidden]="disabled">
        <mat-table
          [dataSource]="dataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          [ngClass]="{ fixheight: dataSource }"
        >
          <ng-container matColumnDef="VoucherId">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              style="max-width: 40px"
              *matHeaderCellDef
              >#</mat-header-cell
            >
            <mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell"
              style="max-width: 40px; max-height: 5px"
              >{{ i + 1 }}</mat-cell
            >
            <mat-footer-cell
              *matFooterCellDef
              style="max-width: 40px"
            ></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Voucher_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Voucher Date
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.VoucherDate }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Voucher_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Voucher No</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Voucher_No }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Voucher_Mode_of_Payment">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Mode</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Voucher_Mode_of_Payment }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="P_No">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              ><div class="width">Chq/Ref Number</div></mat-header-cell
            >

            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.P_No }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="accHead">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Account Head</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.accHead }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>Total:</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="TranTypeDR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >DR Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TranTypeDR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              calculateTranTypeDRTotal()
            }}</mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="TranTypeCR">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >CR Amount</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.TranTypeCR }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>{{
              calculateTranTypeCRTotal()
            }}</mat-footer-cell>
          </ng-container>

          <ng-container matColumnDef="Voucher_Cheque_Clear_Date">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Clear Date</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell j-c-center">
              <div class="datepicker_feild">
                <input
                  [matDatepicker]="picker"
                  dateConvert
                  placeholder="DD/MM/YYYY"
                  name="Voucher_Cheque_Clear_Date{{ row.VoucherId }}"
                  [min]="row.Voucher_Date"
                  id="Voucher_Cheque_Clear_Date{{ row.VoucherId }}"
                  [disabled]="
                    row.SL_Sereies ||
                    (row.Voucher_Cheque_Clear_Date
                      ? !(PageAcess && PageAcess[0]?.Edit == 1)
                      : !(PageAcess && PageAcess[0]?.Add == 1))
                  "
                  [(ngModel)]="row.Voucher_Cheque_Clear_Date"
                  (dateChange)="ClearDateChange(row)"
                  class="form-control input-text-css"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>
          <ng-container matColumnDef="Voucher_Narration">
            <mat-header-cell
              mat-sort-header
              class="grid-header Mx-width"
              *matHeaderCellDef
              >Narration</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell Mx-width">
              {{ row.Voucher_Narration }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          <mat-footer-row
            class="sticky-footer fontcolor"
            *matFooterRowDef="displayedColumns; sticky: true"
            style="background: #4dc3a3"
          ></mat-footer-row>
        </mat-table>
      </div>

      <div
        class="table-responsive mt-2"
        [hidden]="disabled"
        style="font-weight: bold"
      >
        <mat-table
          [dataSource]="dataSourceBalance"
          matSort
          matTableExporter
          #exporter="matTableExporter"
        >
          <ng-container matColumnDef="Particular">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
            >
              Balances
            </mat-header-cell>
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.Particular }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="AsPerBook">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >As Per Book</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.AsPerBook }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="AsPerBank">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >As Per Bank</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.AsPerBank }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="UnClearBalance">
            <mat-header-cell
              mat-sort-header
              class="grid-header"
              *matHeaderCellDef
              >Un-Clear Balance</mat-header-cell
            >
            <mat-cell *matCellDef="let row" class="grid-cell">
              {{ row.UnClearBalance }}
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedBalanceColumns"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedBalanceColumns"
          ></mat-row>
        </mat-table>
        <div class="col-md-12 d-flex">
          <div class="col-md-4 mt-3">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="exportexcelNew()"
            >
              <i
                class="fa fa-file-export mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Export
            </button>
          </div>

          <div class="col-md-2 mt-3" style="margin-left: -190px">
            <button
              class="mt-3 btn font-size-12 button-btn"
              (click)="printdata()"
            >
              <i
                class="fa fa-print mr-1"
                style="font-size: medium; cursor: pointer"
              ></i>
              Print
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row m-0 mt-3 col-md-12 p-0 justify-content-end"
    [hidden]="disabled"
  >
    <button
      type="button"
      (click)="onSave()"
      [class.spinner]="loading"
      class="mt-3 btn font-size-12 button-btn"
    >
      Save
    </button>
  </div>
</div>

<table
  id="reconcile_bank_balance"
  class="table table-bordered bg-white"
  style="font-family: 'Times New Roman', Times, serif; display: none"
>
  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">#</th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Voucher No
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Mode
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Cheque/Ref No.
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Account Head
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      DR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      CR Amount
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Clear Date
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Narration
    </th>
  </tr>

  <tr *ngFor="let x of FileCheckingList; let i = index">
    <td>{{ i + 1 }}</td>
    <td>{{ x.VoucherDate }}</td>
    <td style="text-align: center">{{ x.Voucher_No }}</td>

    <td>{{ x.Voucher_Mode_of_Payment }}</td>
    <td>{{ x.P_No }}</td>
    <td>{{ x.accHead }}</td>
    <td>{{ x.TranTypeDR }}</td>
    <td>{{ x.TranTypeCR }}</td>
    <td>{{ x.Voucher_Cheque_Clear_Date }}</td>
    <td>{{ x.Voucher_Narration }}</td>
  </tr>

  <tfoot>
    <tr>
      <td
        colspan="6"
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <b>Total:</b>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateTranTypeDRTotal() }}</strong>
      </td>

      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong>{{ calculateTranTypeCRTotal() }}</strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
      <td
        style="
          background: #4dc3a3 !important;
          border: 1px solid black;
          text-align: right;
        "
      >
        <strong></strong>
      </td>
    </tr>
  </tfoot>

  <tr></tr>
  <tr></tr>

  <tr>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Balances
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      As Per Book
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      As Per Bank
    </th>
    <th style="background: #4dc3a3 !important; border: 1px solid white">
      Unclear Balance
    </th>
  </tr>

  <tr *ngFor="let x of JsonData; let i = index">
    <td>
      <strong>{{ x.Particular }}</strong>
    </td>
    <td>{{ x.AsPerBook }}</td>
    <td>{{ x.AsPerBank }}</td>
    <td>{{ x.UnClearBalance }}</td>
  </tr>
</table>

<div
  class="modal fade in"
  id="ReconcileBankBalance"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  data-backdrop="false"
  style="background-color: rgba(0, 0, 0, 0.5); overflow: auto"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="modal-content">
      <div cdkDragHandle class="modal-header" style="padding: 10px">
        <h6 class="modal-title" id="myModalLabel" name="myModalLabel">
          Account Statement
        </h6>
        <button
          type="button"
          (click)="onCloseReconcileBankBalance()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true"
            ><i class="far text-black fa-times-circle"></i
          ></span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row m-0">
          <div class="text-right mt-2 mb-2">
            <button
              type="button"
              mat-raised-button
              class="mt-3 btn font-size-12 button-btn"
              (click)="
                onDocsPrint(
                  'ReconcileBankBalancePrint',
                  'Reconcile Bank Balance'
                )
              "
            >
              Print
            </button>
          </div>
        </div>

        <div class="col-md-10 offset-1" id="ReconcileBankBalancePrint">
          <div style="float: right">
            <img
              src="{{ EmployeeProfileBaseUrl }}logo.png"
              alt="logo"
              style="width: 100px; display: inline"
            />
          </div>

          <div style="clear: both">
            <div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h5>{{ currentUser.CompanyName }}</h5>
              </div>
              <div
                style="
                  font-family: 'Times New Roman', Times, serif;
                  text-align: center;
                "
              >
                <h6>Address : {{ currentUser.CompanyAddress }}</h6>
              </div>
            </div>
          </div>

          <div
            style="
              text-align: center;
              font-family: 'Times New Roman', Times, serif;
            "
          >
            Reconcile Bank Balance for : {{ AccountHeadName }}
          </div>

          <div
            style="
              text-align: center;
              font-family: 'Times New Roman', Times, serif;
              margin-top: 8px;
            "
          >
            Reconcile Bank Balance as on :
            {{ VoucherDateTo | date : "dd/MM/yyyy" }}
          </div>

          <div class="table-responsive mt-2">
            <table
              id="reconcile_bank_balance"
              class="table table-bordered bg-white"
              style="font-family: 'Times New Roman', Times, serif"
            >
              <tr>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  #
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Voucher Date
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Voucher No
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Mode
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Cheque/Ref No.
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Account Head
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  DR Amount
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  CR Amount
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Clear Date
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Narration
                </th>
              </tr>

              <tr *ngFor="let x of FileCheckingList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ x.VoucherDate }}</td>
                <td style="text-align: center">{{ x.Voucher_No }}</td>

                <td>{{ x.Voucher_Mode_of_Payment }}</td>
                <td>{{ x.P_No }}</td>
                <td>{{ x.accHead }}</td>
                <td>{{ x.TranTypeDR }}</td>
                <td>{{ x.TranTypeCR }}</td>
                <td>{{ x.Voucher_Cheque_Clear_Date }}</td>
                <td>{{ x.Voucher_Narration }}</td>
              </tr>

              <tfoot>
                <tr>
                  <td
                    colspan="6"
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <b>Total:</b>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateTranTypeDRTotal() }}</strong>
                  </td>

                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong>{{ calculateTranTypeCRTotal() }}</strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong></strong>
                  </td>
                  <td
                    style="
                      background: #4dc3a3 !important;
                      border: 1px solid black;
                      text-align: right;
                    "
                  >
                    <strong></strong>
                  </td>
                </tr>
              </tfoot>

              <br />
              <br />
              <br />
            </table>

            <table>
              <tr>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Balances
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  As Per Book
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  As Per Bank
                </th>
                <th
                  style="
                    background: #4dc3a3 !important;
                    border: 1px solid white;
                  "
                >
                  Unclear Balance
                </th>
              </tr>

              <tr *ngFor="let x of JsonData; let i = index">
                <td>
                  <strong>{{ x.Particular }}</strong>
                </td>
                <td>{{ x.AsPerBook }}</td>
                <td>{{ x.AsPerBank }}</td>
                <td>{{ x.UnClearBalance }}</td>
              </tr>
            </table>

            <div
              id="abc"
              style="
                float: right;
                text-align: right;
                font-family: 'Times New Roman', Times, serif;
              "
            >
              Issue Date & Time: {{ Cur_Date | date : "dd/MM/yyyy hh:mm:ss" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
