import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { Observable } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LmsService } from "../../_Lms/services/lms.service";
import { Router } from "@angular/router";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { DialogService } from "../../Shared/dialog.service";
import { map, startWith } from "rxjs/operators";
import { ToWords } from "to-words";
import { MasterService } from "../../Shared/app.Masters.Service";
import * as moment from "moment";
import { ActivatedRoute, Params } from "@angular/router";
import { DatePipe } from "@angular/common";
import { CommonFunctions } from "src/app/CommonComponent/function/commonfunctions";
import { constantUrl } from "src/app/Shared/constantUrl";

@Component({
  selector: "app-reconcile-balance",
  templateUrl: "./reconcile-balance.component.html",
  styleUrls: ["./reconcile-balance.component.scss"],
})
export class ReconcileBalanceComponent implements OnInit {
  chhead: any = "";
  showSpinner: boolean = false;
  currentUser: any;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  totalCalculator: any;
  loading: boolean = false;
  FileCheckingList: any[] = [];
  JsonData: any[] = [];
  BalanceList: any[] = [];
  dataSource: any;
  dataSourceBalance: any;
  dataSourceAccount: any;
  VoucherDateFrom: any;
  VoucherDateTo: any;
  Voucher: any[] = [];
  AccountHead: any;
  TransationDetail: any = "0";
  AccountName: any;
  AccountHeadDropdown: any[] = [];
  displayedColumns: string[] = [
    "VoucherId",
    "Voucher_Date",
    "Voucher_No",
    "Voucher_Mode_of_Payment",
    "P_No",
    "accHead",
    "TranTypeDR",
    "TranTypeCR",
    "Voucher_Cheque_Clear_Date",
    "Voucher_Narration",
  ];
  displayedBalanceColumns: string[] = [
    "Particular",
    "AsPerBook",
    "AsPerBank",
    "UnClearBalance",
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  groupList: any[] = [];
  groupDropdown: any[] = [];
  BankList: any[] = [];
  groupModel: any = {};
  dropdownSettings: any = {};
  myForm: UntypedFormGroup;
  AccountControl = new UntypedFormControl();
  disabled = true;
  ShowFilter = true;
  accountTagDropdown: any[] = [];
  chargesHeadDropdown: any[] = [];
  voucherModel: any = { Voucher: {}, Voucher_Detail: [] };
  filteredAccountHead: Observable<any[]>;
  limitSelection = false;
  AsPerBankOPBal: any;
  FAsPerBankOPBal: any;
  AsPerBookOPBal: any;
  FAsPerBookOPBal: any;

  AsPerBankCLBal: any;
  FAsPerBankCLBal: any;
  AsPerBookCLBal: any;
  FAsPerBookCLBal: any;
  FyearId: any;
  maxDate: any;
  minDate: any;
  IsGroupWise: any = "1";
  pipe = new DatePipe("en-US");
  Cur_Date: any = new Date();

  EmployeeProfileBaseUrl: any = constantUrl.apiProfilePicUrl;
  AccountHeadName: any;
  PageAcess: any;
  constructor(
    private dataSharingService: DataSharingService,
    private snackBar: MatSnackBar,
    private _MasterService: MasterService,
    private router: Router,
    private route: ActivatedRoute,
    private lmsService: LmsService,
    private dialog: DialogService,
    private fb: UntypedFormBuilder,
    private _commonfunctions: CommonFunctions
  ) {
    
  }
  ngOnInit() {
    this.dataSharingService.HeaderTitle.next("Reconcile Bank Balance");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.getAccounthead();
    this.route.queryParams.subscribe((params) => {
      this.FyearId = params["fyear"];
      console.log("paran", this.FyearId);
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.minDate = new Date(moment(res[0].fystart).format("MM/DD/YYYY"));
          this.maxDate = new Date(moment(res[0].fyent).format("MM/DD/YYYY"));
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          if (this.maxDate > new Date() && this.minDate < new Date())
            this.VoucherDateTo = new Date();
          else this.VoucherDateTo = this.maxDate;
          this.dataSource = null;
        });
    });
    console.log("Time : ", this.minDate);
    //this.dropdownSettings = {
    //  singleSelection: true,
    //  idField: 'AccountId',
    //  textField: 'Account_Name',
    // // selectAllText: 'Select All',
    ////  unSelectAllText: 'UnSelect All',
    //  allowSearchFilter: this.ShowFilter
    //};
    //this.filteredAccountHead = this.AccountControl.valueChanges.pipe(startWith(''),
    //  map(value => typeof value === 'string' ? value : value.name),
    //  map(name => name ? this._filter(name) : this.AccountHeadDropdown.slice())
    //);
  }
  onAccountHeadhSelect(event: any) {
    this.AccountHead = event.option.value.AccountId;
    this.AccountHeadName = event.option.value.Account_Name;
    console.log("ACCNAME", this.AccountHeadName);
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.AccountHeadDropdown.filter(
      (option) => option.Account_Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayFn(account: any): string {
    return account && account.Account_Name ? account.Account_Name : "";
  }
  getAccounthead() {
    var User = JSON.parse(sessionStorage.getItem("currentUser"));
    this.lmsService
      .GET_ACCOUNT_MASTER_BY_TAG({ TagName: "Bank", LoginUserId: User.userId })
      .subscribe((res: any) => {
        this.AccountHeadDropdown = res;
        //for (var i = 0; i < res.length; i++) {
        //  this.chargesHeadDropdown.push({ id: res[i]["AccountId"], name: res[i]["Account_Name"]});
        //}
        this.filteredAccountHead = this.AccountControl.valueChanges.pipe(
          startWith(""),
          map((value) =>
            typeof value === "string" ? value : value.Account_Name
          ),
          map((name) =>
            name ? this._filter(name) : this.AccountHeadDropdown.slice()
          )
        );

        console.log("acc", this.filteredAccountHead);
      });
  }

  getChargeshead() {
    this._MasterService
      .GetProductChargesHeadDropDown()
      .subscribe((res: any) => {
        this.chargesHeadDropdown = res;
      });
  }
  onSearchAccountStatement() {
    this.showSpinner = true;
    this.lmsService
      .Get_Reconcile_Balance({
        AccountId: this.AccountHead,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log("res", res);
        this.BalanceList = [];
        if (this.TransationDetail == "0") {
          this.FileCheckingList = JSON.parse(JSON.stringify(res.Item1));
          this.FileCheckingList.forEach((x) => {
            x.Clear_Date = new Date(x.Voucher_Cheque_Clear_Date.split("T")[0]);
            x.Clear_Date = new Date(x.Clear_Date.setHours(0, 0, 0, 0));
          });
          this.FileCheckingList = this.FileCheckingList.filter(
            (item) =>
              item.Voucher_Cheque_Clear_Date == null ||
              item.Voucher_Cheque_Clear_Date == "0001-01-01T00:00:00" ||
              item.Clear_Date > this.VoucherDateTo
          );
        } else this.FileCheckingList = JSON.parse(JSON.stringify(res.Item1));

        this.FileCheckingList.forEach((x) => {
          if (x.Voucher_Cheque_Clear_Date == "0001-01-01T00:00:00") {
            x.Voucher_Cheque_Clear_Date = "";
          } else {
            x.Voucher_Cheque_Clear_Date = new Date(
              x.Voucher_Cheque_Clear_Date.split("T")[0]
            );
          }
          x.SL_Sereies = false;
          if (x.Voucher_No.includes("SL")) x.SL_Sereies = true;
        });
        this.dataSource = new MatTableDataSource(this.FileCheckingList);
        this.dataSource.sort = this.sort;
        this.disabled = false;
        // as Per book OP bal
        if (res.Item2.length > 0) {
          if (res.Item2[0].OpeningBalanceDR > 0) {
            this.AsPerBookOPBal = res.Item2[0].OpeningBalanceDR + " DR";
            this.FAsPerBookOPBal = res.Item2[0].OpeningBalanceDR;
          } else {
            this.AsPerBookOPBal = res.Item2[0].OpeningBalanceCR + " CR";
            this.FAsPerBookOPBal = "-" + res.Item2[0].OpeningBalanceCR;
          }
          // as Per book CL bal
          if (res.Item2[0].ClosingBalanceDR > 0) {
            this.AsPerBookCLBal = res.Item2[0].ClosingBalanceDR + " DR";
            this.FAsPerBookCLBal = res.Item2[0].ClosingBalanceDR;
          } else {
            this.AsPerBookCLBal = res.Item2[0].ClosingBalanceCR + " CR";
            this.FAsPerBookCLBal = "-" + res.Item2[0].ClosingBalanceCR;
          }
        } else {
          this.AsPerBookOPBal = "0.00 DR";
          this.FAsPerBookOPBal = "0.00";
          this.AsPerBookCLBal = "0.00 DR";
          this.FAsPerBookCLBal = "0.00";
        }
        // as Per Bank OP bal

        if (res.Item3.length > 0) {
          if (res.Item3[0].OpeningBalanceDR > 0) {
            this.AsPerBankOPBal = res.Item3[0].OpeningBalanceDR + " DR";
            this.FAsPerBankOPBal = res.Item3[0].OpeningBalanceDR;
          } else {
            this.AsPerBankOPBal = res.Item3[0].OpeningBalanceCR + " CR";
            this.FAsPerBankOPBal = "-" + res.Item3[0].OpeningBalanceCR;
          }

          // as Per Bank CL bal
          if (res.Item3[0].ClosingBalanceDR > 0) {
            this.AsPerBankCLBal = res.Item3[0].ClosingBalanceDR + " DR";
            this.FAsPerBankCLBal = res.Item3[0].ClosingBalanceDR;
          } else {
            this.AsPerBankCLBal = res.Item3[0].ClosingBalanceCR + " CR";
            this.FAsPerBankCLBal = "-" + res.Item3[0].ClosingBalanceCR;
          }
        } else {
          this.AsPerBankOPBal = "0.00 DR";
          this.FAsPerBankOPBal = "0.00";
          this.AsPerBankCLBal = "0.00 DR";
          this.FAsPerBankCLBal = "0.00";
        }

        var UnClearBalanceOP, UnClearBalanceCL;
        if (Number(this.FAsPerBankOPBal) - Number(this.FAsPerBookOPBal) >= 0)
          UnClearBalanceOP =
            (
              Number(this.FAsPerBankOPBal) - Number(this.FAsPerBookOPBal)
            ).toFixed(2) + " DR";
        else {
          var aa = (
            Number(this.FAsPerBankOPBal) - Number(this.FAsPerBookOPBal)
          ).toString();
          UnClearBalanceOP = Number(aa.split("-")[1]).toFixed(2) + " CR";
        }

        if (Number(this.FAsPerBankCLBal) - Number(this.FAsPerBookCLBal) >= 0)
          UnClearBalanceCL =
            (
              Number(this.FAsPerBankCLBal) - Number(this.FAsPerBookCLBal)
            ).toFixed(2) + " DR";
        else {
          var aa = (
            Number(this.FAsPerBankCLBal) - Number(this.FAsPerBookCLBal)
          ).toString();
          UnClearBalanceCL = Number(aa.split("-")[1]).toFixed(2) + " CR";
        }
        this.BalanceList.push({
          Particular: "Opening Balance",
          AsPerBook: this.AsPerBookOPBal,
          AsPerBank: this.AsPerBankOPBal,
          UnClearBalance: UnClearBalanceOP,
        });
        this.BalanceList.push({
          Particular: "Closing Balance",
          AsPerBook: this.AsPerBookCLBal,
          AsPerBank: this.AsPerBankCLBal,
          UnClearBalance: UnClearBalanceCL,
        });

        this.dataSourceBalance = new MatTableDataSource(this.BalanceList);
        // console.log("data",this.dataSourceBalance);
        this.JsonData = this.dataSourceBalance.filteredData;
        console.log("data", this.JsonData);

        this.dataSourceBalance.sort = this.sort;
        //this.AsPerBookOPDRBal = res.Item2[0].OpeningBalanceDR;
        //this.AsPerBookOPCRBal = res.Item2[0].OpeningBalanceCR;
        //this.AsPerBookCLDRBal = res.Item2[0].ClosingBalanceDR;
        //this.AsPerBookCLCRBal = res.Item2[0].ClosingBalanceCR;

        //this.AsPerBankOPDRBal = res.Item3[0].OpeningBalanceDR;
        //this.AsPerBankOPCRBal = res.Item3[0].OpeningBalanceCR;
        //this.AsPerBankCLDRBal = res.Item3[0].ClosingBalanceDR;
        //this.AsPerBankCLCRBal = res.Item4[0].ClosingBalanceCR;


        let AllPage = JSON.parse(sessionStorage.getItem("AllPageAcess"));
        console.log("All PAGES", AllPage);
        this.PageAcess = AllPage.filter(
          (x) => x.Page_Name == "Reconcile Bank Balance"
        );


        this.showSpinner = false;
      });
  }
  goToDetail(row) {
    $("#AccountingDetail").modal("show");
    $("#AccountingDetail").css("z-index", "1050");
    this.lmsService
      .Get_Acc_Leadger({
        AccountId: row.AccountId,
        From: this.VoucherDateFrom,
        To: this.VoucherDateTo,
        StartYear: this.minDate.getFullYear(),
      })
      .subscribe((res: any) => {
        console.log(res);
        this.FileCheckingList = JSON.parse(JSON.stringify(res));
        this.dataSourceAccount = new MatTableDataSource(this.FileCheckingList);
        this.dataSourceAccount.sort = this.sort;
        this.AccountName = row.Account_Name;
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip();
        }, 1000);
        this.showSpinner = false;
      });
  }
  OnCloseVoucherDetail() {
    $("#VoucherDetail").modal("hide");
  }
  OnCloseAccountingDetail() {
    $("#AccountingDetail").modal("hide");
  }
  goToDetailVoucher(row) {
    $("#VoucherDetail").modal("show");
    $("#VoucherDetail").css("z-index", "1060");
    this.lmsService
      .LMS_Get_Voucher_Details({ VoucherId: row.voucherid })
      .subscribe((res: any) => {
        console.log("res", res);
        this.voucherModel.Voucher = res.Item1[0];
        this.voucherModel.Voucher_Detail = res.Item2;
        this.voucherModel.Voucher_Detail.forEach((x) => {
          x.AmountDR = 0.0;
          x.AmountCR = 0.0;
          if (x.TranType == "CR") x.AmountCR = x.Amount;
          if (x.TranType == "DR") x.AmountDR = x.Amount;
        });
        if (
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == "" ||
          this.voucherModel.Voucher.Voucher_Mode_of_Payment == null
        )
          this.voucherModel.Voucher.Voucher_Mode_of_Payment = "CASH";
        this.onVoucherTagChange();
      });
  }
  getTotalCalculation() {
    let _total: any = 0;
    let _totalCR: any = 0;
    let _totalTX: any = 0;
    this.voucherModel.Voucher_Detail.forEach((obj: any) => {
      _total = _total + obj.Amount || 0;
      _totalCR = _totalCR + obj.Amount || 0;
      obj.TaxSlab.forEach((tobj: any) => {
        _total = _total + tobj.TaxAmount || 0;
        _totalTX = _totalTX + tobj.TaxAmount || 0;
      });
    });
    this.totalCalculator = {
      total: Number(_total),
      totalCR: Number(_totalCR),
      totaltax: Number(_totalTX),
      totalInWord: new ToWords().convert(Number(_total)),
    };
  }
  //onChangeMode() {
  //  if (this.voucherModel.Voucher.Voucher_Mode_of_Payment == 'Cheque') {
  //    this.voucherModel.Voucher.Voucher_Cheque_No = "";
  //    this.voucherModel.Voucher.Voucher_Cheque_Date = "";
  //    this.voucherModel.Voucher.Voucher_Bank_Name = "";
  //    this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
  //  }
  //  else {
  //    this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
  //    this.voucherModel.Voucher.Voucher_E_Ref_No = "";
  //  }
  //}
  onChangeChargesHead(data: any, dindex: any) {
    //let _find = this.voucherModel.Voucher_Detail.find((obj: any, index: any) => {
    //  return index != dindex && obj.CaseNo == data.CaseNo && obj.ChargeHeadId == data.ChargeHeadId
    //});
    //if (_find) {
    //  this.snackBar.openFromComponent(SnackbarComponent, { data: "This Account head already selected.", ...this.configSuccess });
    //  data.ChargeHeadId = "";
    //  data.Amount = "";
    //  data.AccountId = "";
    //  data.ChargeHeadCategory = "";
    //  data.TaxSlab = [];
    //  if (this.voucherModel.Voucher.Voucher_Sub_Type == 'Accounting') {
    //    data.BranchId = "";
    //  }
    //  this.getTotalCalculation();
    //  return;
    //}
    data.Amount = "";
    data.TaxSlab = [];
    this.lmsService
      .Get_AccountHead_For_VoucherEntry({
        HeadId: data.ChargeHeadId,
        VoucherSubType: this.voucherModel.Voucher.Voucher_Sub_Type,
        ProductId: data.ProductId || 0,
        TaxType: data.TranType,
      })
      .subscribe((res: any) => {
        if (res.Item1[0]) {
          if (res.Item1[0].AccountId) {
            data.AccountId = res.Item1[0].AccountId;
            data.ChargeHeadCategory = res.Item1[0].AccountName;
            data.TaxSlab = res.Item2;
          } else {
            data.ChargeHeadId = "";
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "category does not have account head.",
              ...this.configSuccess,
            });
          }
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "No data Found.",
            ...this.configSuccess,
          });
        }
      });
  }
  onVoucherTagChange() {
    //this.voucherModel.Voucher.Voucher_Mode_of_Payment = "";
    //this.voucherModel.Voucher.Voucher_E_Ref_No = "";
    //this.voucherModel.Voucher.Voucher_E_Transaction_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Clear_Date = "";
    //this.voucherModel.Voucher.Voucher_Bank_Name = "";
    //this.voucherModel.Voucher.Voucher_Cheque_Date = "";
    //this.voucherModel.Voucher.Voucher_Cheque_No = "";
    this.Get_Acc_Accounts_By_Tag();
  }
  Get_Acc_Accounts_By_Tag() {
    this.voucherModel.Voucher.DRAccountId = "";
    if (this.voucherModel.Voucher.Voucher_Tag == null)
      this.voucherModel.Voucher.Voucher_Tag = "CASH";
    this.lmsService
      .Get_Acc_Accounts_By_Tag({
        Account_Tag: this.voucherModel.Voucher.Voucher_Tag,
        Voucher_Date: new Date(this.voucherModel.Voucher.Voucher_Date),
      })
      .subscribe((res: any) => {
        this.accountTagDropdown = res;
      });
  }
  calculateTranTypeDRTotal() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeCRTotal() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateOpeningBalanceDRTotal() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["OpeningBalanceDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateOpeningBalanceCRTotal() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["OpeningBalanceCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateClosingBalanceDRTotal() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["ClosingBalanceDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateClosingBalanceCRTotal() {
    if (this.dataSource != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        sum += Number(this.dataSource.filteredData[i]["ClosingBalanceCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeDR() {
    if (this.dataSourceAccount != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceAccount.filteredData.length; i++) {
        sum += Number(this.dataSourceAccount.filteredData[i]["TranTypeDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeCR() {
    if (this.dataSourceAccount != null) {
      let sum = 0.0;
      for (let i = 0; i < this.dataSourceAccount.filteredData.length; i++) {
        sum += Number(this.dataSourceAccount.filteredData[i]["TranTypeCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateACTranTypeTotal() {
    if (this.dataSourceAccount != null) {
      let sum = 0;
      let DR = this.calculateACTranTypeDR();
      let CR = this.calculateACTranTypeCR();
      sum = Number(DR) - Number(CR);
      if (sum > 0) return sum.toFixed(2).toString() + " DR";
      else return sum.toFixed(2).toString().split("-")[1] + " CR";
    }
    return 0;
  }

  calculateTranTypeCR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountCR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  calculateTranTypeDR() {
    if (this.voucherModel.Voucher_Detail != null) {
      let sum = 0.0;
      for (let i = 0; i < this.voucherModel.Voucher_Detail.length; i++) {
        sum += Number(this.voucherModel.Voucher_Detail[i]["AmountDR"]);
      }
      return sum.toFixed(2);
    }
    return 0;
  }
  DateChange() {
    if (this.VoucherDateFrom > this.VoucherDateTo) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "VoucherDate To greater than VoucherDate From.",
        ...this.configSuccess,
      });
      this.lmsService
        .Get_FinancialYear({ Id: this.FyearId })
        .subscribe((res: any) => {
          this.VoucherDateFrom = new Date(
            moment(res[0].fystart).format("MM/DD/YYYY")
          );
          this.VoucherDateTo = new Date();
        });
    }
  }
  ClearDateChange(row) {
    if (
      new Date(moment(row.Voucher_Cheque_Clear_Date).format("MM/DD/YYYY")) <
      new Date(moment(row.Voucher_Date).format("MM/DD/YYYY"))
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Clear Date  not less then " + row.VoucherDate,
        ...this.configSuccess,
      });

      this.dataSource.filteredData.forEach((x) => {
        if (x.VoucherId == row.VoucherId) x.Voucher_Cheque_Clear_Date = null;
      });

      var element1 = <HTMLInputElement>(
        document.getElementById("Voucher_Cheque_Clear_Date" + row.VoucherId)
      );
      element1.value = null;
    }
  }
  onSave() {
    //var a = 0, MSG ='Update_Clear Date Successfully Saved';
    //console.log("his.dataSource.filteredData", this.dataSource.filteredData);

    //this.dataSource.filteredData.forEach(x => {
    //    console.log("Date", this.pipe.transform(x.Voucher_Cheque_Clear_Date, 'MM/dd/yyyy'));

    //  this.lmsService.LMS_Update_Voucher_Cleardate({ VoucherId: x.VoucherId, ClearDate: x.Voucher_Cheque_Clear_Date }).subscribe((res) => {
    //    a++;
    //    console.log("Date", this.pipe.transform(x.Voucher_Cheque_Clear_Date, 'MM/dd/yyyy'));
    //  if (a == this.dataSource.filteredData.length) {
    //    this.snackBar.openFromComponent(SnackbarComponent, { data: MSG, ...this.configSuccess });
    //    this.TransationDetail = "0";
    //    this.onSearchAccountStatement();
    //    }
    //  });
    //});
    var JSONdata = [];
    this.dataSource.filteredData.forEach((x) => {
      //if (x.Voucher_Cheque_Clear_Date) {
      JSONdata.push({
        ClearDate: this.pipe.transform(
          x.Voucher_Cheque_Clear_Date,
          "MM/dd/yyyy"
        ),
        VoucherId: x.VoucherId,
      });
      //}
    });

    this.lmsService
      .LMS_Update_Voucher_Cleardate({ JSON: JSON.stringify(JSONdata) })
      .subscribe((res) => {
        if (res[0].CODE >= 0) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: res[0].MSG,
            ...this.configSuccess,
          });

          this.onSearchAccountStatement();
        }
      });
  }
  dateChange() {
    if (this.VoucherDateFrom > this.maxDate) {
      this.VoucherDateFrom = this.minDate;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data:
          "Date From not greater then " +
          moment(this.maxDate).format("MM/DD/YYYY"),
        ...this.configSuccess,
      });
    }
    if (this.VoucherDateFrom < this.minDate) {
      this.VoucherDateFrom = this.minDate;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data:
          "Date From not less then " +
          moment(this.minDate).format("MM/DD/YYYY"),
        ...this.configSuccess,
      });
    }

    if (this.VoucherDateTo > this.maxDate) {
      this.VoucherDateTo = this.maxDate;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data:
          "Date To not greater then " +
          moment(this.maxDate).format("MM/DD/YYYY"),
        ...this.configSuccess,
      });
    }
    if (this.VoucherDateTo < this.minDate) {
      this.VoucherDateTo = this.maxDate;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data:
          "Date To not less then " + moment(this.minDate).format("MM/DD/YYYY"),
        ...this.configSuccess,
      });
    }
  }

  exportexcelNew(): void {
    const uri = "data:application/vnd.ms-excel;base64,";
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    const base64 = (s: string): string =>
      window.btoa(unescape(encodeURIComponent(s)));
    const format = (s: string, c: { [key: string]: string }): string =>
      s.replace(/{(\w+)}/g, (m, p) => c[p]);

    const table = document.getElementById(
      "reconcile_bank_balance"
    ) as HTMLTableElement;
    const worksheetName = "RENCONCILE BANK BALANCE"; // Set the desired worksheet name here
    const ctx = { worksheet: worksheetName, table: table.innerHTML };
    const downloadLink = document.createElement("a");

    // Generate the data URI for the Excel file
    downloadLink.href = uri + base64(format(template, ctx));

    // Set the file name for the download
    downloadLink.download = worksheetName + ".xls";

    // Trigger the download
    downloadLink.click();
  }

  onCloseReconcileBankBalance() {
    $("#ReconcileBankBalance").modal("hide");
  }

  printdata() {
    $("#ReconcileBankBalance").modal("show");
    $("#ReconcileBankBalance").css("z-index", "1050");
  }

  onDocsPrint(divName, Header) {
    this._commonfunctions.PrintDocuments(divName, Header, "");
  }

  applyFilter(filterValue: String) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
